<template>
  <v-card elevation="8" outlined color="grey lighten-4" class="w-11/12 mx-auto">
      <v-row class="p-4">
        <v-col cols="2">
            <v-img           
              :src="require('../assets/logo.png')"
              contain
              height="60"/>
        </v-col>
        <v-col cols="10">
          <div class="text-xl lg:text-3xl font-weight-bold text-center">
            <div class="mt-2">
              ОБЩА ИНФОРМАЦИЯ ЗА АРТЕКС БЪЛГАРИЯ ЕООД – КЪРДЖАЛИ
            </div>
          </div>

        </v-col>

        <v-col cols="12" class="text-justify lg:text-xl">

          <p class="subheading font-weight-regular">
            &emsp; Артекс България ЕООД гр.Кърджали е Еднолично дружество с ограничена отговорност, регистрирано в Регистъра на търговските дружества при Кърджалийски окръжен съд 
            през 1997 година, със седалище в гр.Кърджали на адрес бул.България № 2Б.
            Дружеството се представлява и управлява от Светла Петкова.
          </p>
          <p>&emsp; Предметът на дейност е производство и реализация на мъжко, дамско и детско бельо. Пре-обладаващата част от производствената програма се осъществява на база договори за 
          изработка на ишлеме. Фирмата е в състояние да се ангажира с производство на артикули под собствената марка на възложителя, в съответствие с неговите изисквания за количества и 
          качество.  
          </p>
          <p>&emsp; Дейността се осъществява в собствена производствена и складова база – шивашките цехове са оборудвани със специализирани машини за производство на бельо. Работещите – продуктивен 
          и непродуктивен персонал са около 230 човека. Наличният капацитет предполага възможности 
          за предоставяне на оптимални цени и условия на доставка. Полагат се усилия за индивидуален подход към всеки клиент, съобразен със специфичните изисквания, както и изпълнение на поръчките с необходимата компетентност, качествено и в указаните срокове. 
          </p>
          <p>&emsp; Действащата система за  социална отговорност в дружеството е в съответствие с изискванията 
          на стандарт SA 8000:2014, което е потвърдено с издадения сертификат.
          </p>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>